import { breakpoints, device } from "./breakpoints";
import theme from "./theme";
import { addDays } from "./addDays";
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

library.add(faSpinner)


export { device, breakpoints, theme, addDays };

