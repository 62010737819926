// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-hotsnail-mail-redirection-js": () => import("./../src/pages/about-hotsnail-mail-redirection.js" /* webpackChunkName: "component---src-pages-about-hotsnail-mail-redirection-js" */),
  "component---src-pages-blog-2-js": () => import("./../src/pages/blog-2.js" /* webpackChunkName: "component---src-pages-blog-2-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-australia-products-js": () => import("./../src/pages/blog/Australia-products.js" /* webpackChunkName: "component---src-pages-blog-australia-products-js" */),
  "component---src-pages-blog-australia-to-china-js": () => import("./../src/pages/blog/Australia-to-china.js" /* webpackChunkName: "component---src-pages-blog-australia-to-china-js" */),
  "component---src-pages-blog-australia-to-france-js": () => import("./../src/pages/blog/Australia-to-france.js" /* webpackChunkName: "component---src-pages-blog-australia-to-france-js" */),
  "component---src-pages-blog-australia-to-germany-js": () => import("./../src/pages/blog/Australia-to-germany.js" /* webpackChunkName: "component---src-pages-blog-australia-to-germany-js" */),
  "component---src-pages-blog-australia-to-japan-js": () => import("./../src/pages/blog/Australia-to-japan.js" /* webpackChunkName: "component---src-pages-blog-australia-to-japan-js" */),
  "component---src-pages-blog-australia-to-taiwan-js": () => import("./../src/pages/blog/Australia-to-taiwan.js" /* webpackChunkName: "component---src-pages-blog-australia-to-taiwan-js" */),
  "component---src-pages-blog-australia-to-usa-js": () => import("./../src/pages/blog/Australia-to-usa.js" /* webpackChunkName: "component---src-pages-blog-australia-to-usa-js" */),
  "component---src-pages-blog-covid-19-js": () => import("./../src/pages/blog/Covid-19.js" /* webpackChunkName: "component---src-pages-blog-covid-19-js" */),
  "component---src-pages-blog-fa-qs-on-mail-forwarding-js": () => import("./../src/pages/blog/FAQs-on-mail-forwarding.js" /* webpackChunkName: "component---src-pages-blog-fa-qs-on-mail-forwarding-js" */),
  "component---src-pages-blog-hot-snail-services-js": () => import("./../src/pages/blog/HotSnail-Services.js" /* webpackChunkName: "component---src-pages-blog-hot-snail-services-js" */),
  "component---src-pages-blog-introducing-sharemaster-js": () => import("./../src/pages/blog/introducing-sharemaster.js" /* webpackChunkName: "component---src-pages-blog-introducing-sharemaster-js" */),
  "component---src-pages-blog-invest-in-parcelforwarding-js": () => import("./../src/pages/blog/Invest-in-parcelforwarding.js" /* webpackChunkName: "component---src-pages-blog-invest-in-parcelforwarding-js" */),
  "component---src-pages-blog-lowest-prices-js": () => import("./../src/pages/blog/Lowest-prices.js" /* webpackChunkName: "component---src-pages-blog-lowest-prices-js" */),
  "component---src-pages-blog-parcel-forwarding-is-essential-js": () => import("./../src/pages/blog/ParcelForwarding-is-essential.js" /* webpackChunkName: "component---src-pages-blog-parcel-forwarding-is-essential-js" */),
  "component---src-pages-blog-reset-share-point-version-js": () => import("./../src/pages/blog/Reset-SharePoint-Version.js" /* webpackChunkName: "component---src-pages-blog-reset-share-point-version-js" */),
  "component---src-pages-blog-top-3-concerns-js": () => import("./../src/pages/blog/Top-3-concerns.js" /* webpackChunkName: "component---src-pages-blog-top-3-concerns-js" */),
  "component---src-pages-blog-virtual-mailboxes-in-australia-js": () => import("./../src/pages/blog/virtual-mailboxes-in-Australia.js" /* webpackChunkName: "component---src-pages-blog-virtual-mailboxes-in-australia-js" */),
  "component---src-pages-blog-virtual-mailboxes-services-js": () => import("./../src/pages/blog/Virtual-mailboxes-services.js" /* webpackChunkName: "component---src-pages-blog-virtual-mailboxes-services-js" */),
  "component---src-pages-calculator-js": () => import("./../src/pages/calculator.js" /* webpackChunkName: "component---src-pages-calculator-js" */),
  "component---src-pages-contact-us-js": () => import("./../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-document-scanning-js": () => import("./../src/pages/document-scanning.js" /* webpackChunkName: "component---src-pages-document-scanning-js" */),
  "component---src-pages-drop-shipping-australia-js": () => import("./../src/pages/drop-shipping-australia.js" /* webpackChunkName: "component---src-pages-drop-shipping-australia-js" */),
  "component---src-pages-faq-js": () => import("./../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-how-mail-management-works-js": () => import("./../src/pages/how-mail-management-works.js" /* webpackChunkName: "component---src-pages-how-mail-management-works-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-parcel-forwarding-australia-js": () => import("./../src/pages/parcel-forwarding-australia.js" /* webpackChunkName: "component---src-pages-parcel-forwarding-australia-js" */),
  "component---src-pages-parcel-forwarding-calculator-js": () => import("./../src/pages/parcel-forwarding-calculator.js" /* webpackChunkName: "component---src-pages-parcel-forwarding-calculator-js" */),
  "component---src-pages-pricing-js": () => import("./../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-js": () => import("./../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-terms-conditions-js": () => import("./../src/pages/terms-conditions.js" /* webpackChunkName: "component---src-pages-terms-conditions-js" */),
  "component---src-pages-testimonals-js": () => import("./../src/pages/Testimonals.js" /* webpackChunkName: "component---src-pages-testimonals-js" */)
}

